

























import Vue from 'vue';
import ThemeSwitcher from '../components/ThemeSwitcher.vue';
import Project, { Project as ProjectType } from '../components/Project.vue';

export default Vue.extend({
  name: 'home',
  components: {
    ThemeSwitcher,
    Project,
  },
  data: () => ({
    projects: [
      {
        title: 'Portfolio',
        description: 'Thats the page you are on right now!',
        tech: 'Vue',
        links: [
          { type: 'web', url: 'https://xunafay.io' },
          { type: 'github', url: 'https://github.com/xunafay/portfolio' },
        ],
      },
      {
        title: 'Stream Elements Discord Bot',
        description: 'A discord implementation of the Stream Elements Twitch chat bot',
        tech: 'Typescript',
        links: [{ type: 'github', url: 'https://github.com/xunafay/strimmer-elements' }],
      },
      {
        title: 'Twitch Clip Command',
        description: 'A tool to automatically create a twitch clip with a simple chat command',
        tech: 'Typescript',
        links: [{ type: 'github', url: 'https://github.com/xunafay/twitch-clip-command' }],
      },
    ] as Array<ProjectType>,
  }),
});
