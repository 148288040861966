













import Vue from 'vue';

export interface Project {
  title: string;
  description: string;
  tech: string;
  links: Array<ProjectLink>;
}

export interface ProjectLink {
  type: 'github' | 'web';
  url: string;
}

export default Vue.extend({
  name: 'project',
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
});
