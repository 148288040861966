







import Vue from 'vue';

export default Vue.extend({
  name: 'theme-switcher',
  data: () => ({
    theme: 'light',
  }),
  created() {
    if (localStorage.getItem('theme') === 'dark') {
      document.documentElement.classList.add('theme--dark');
      this.theme = 'dark';
    } else {
      document.documentElement.classList.add('theme--light');
      this.theme = 'light';
    }
  },
  methods: {
    toggleTheme() {
      if (this.theme === 'light') {
        this.theme = 'dark';
        document.documentElement.classList.remove('theme--light');
        document.documentElement.classList.add('theme--dark');
        localStorage.setItem('theme', 'dark');
      } else {
        document.documentElement.classList.remove('theme--dark');
        document.documentElement.classList.add('theme--light');
        this.theme = 'light';
        localStorage.removeItem('theme');
      }
    },
  },
});
